export default function guidelineFor(metaKey) {
  return guidelines[metaKey] || `Write guideline for ${metaKey}`;
}

const guidelines = {
  CompanyContentAlias: {
    name: "Company alias",
    markdown: `
- Is the **company** often referred to by this **alias**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  ContactContentAlias: {
    name: "Contact alias",
    markdown: `
- Is the **contact** often referred to by this **alias**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyContentAlias: {
    name: "Property alias",
    markdown: `
- Is the **property** often referred to by this **alias**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightContentAlias: {
    name: "Property right alias",
    markdown: `
- Is the **property right** often referred to by this **alias**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringContentAlias: {
    name: "Land covering alias",
    markdown: `
- Is the **building or surface covering** often referred to by this **alias**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringGroupingContentAlias: {
    name: "Land covering grouping alias",
    markdown: `
- Is the **combined structure** often referred to by this **alias**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  CashflowSchedulename: {
    name: "Cashflow name",
    markdown: `
- Is this an appropriate **name** for this **cashflow schedule**?
    `,
  },
  Contactname: {
    name: "Contact name",
    markdown: `
- Is this the person's proper **name**? The level of formality should match how the market refers to the person.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Companyname: {
    name: "Company name",
    markdown: `
- Is this the company's proper **name**? A **common name (e.g., "Tower Hunt") is preferable** to a legal name (e.g., "Tower Hunt, Inc", "Tower Hunt, LLC"). Read our {% inlineRouterLink articleId="how-to-name-companies" newTab="true" %}company naming guide{% /inlineRouterLink %}.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Companyticker_symbol: {
    name: "Company ticker symbol",
    markdown: `
- Is this the company's **stock ticker symbol**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Propertyname: {
    name: "Property name",
    markdown: `
- Is this the property's proper **name**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see what the property diagram includes.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Investmentname: {
    name: "Investment name",
    markdown: `
- Do people in the market know the **deal** by this **name**?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Huntname: {
    name: "Hunt name",
    markdown: `
- Does this **name** effectively describe what this **hunt** is pursuing?
    `,
  },
  HuntGeographyIntentname: {
    name: "Target geography name",
    markdown: `
- Does this **name** effectively describe the **geographic market**?
    `,
  },
  HuntSpaceUsage: {
    name: "Triggering lease",
    markdown: `
- Is this **existing lease/license** affected by the **hunt**?
    `,
  },
  HuntHuntGeographyIntentCompanyInvolvement: {
    name: "Target geography company",
    markdown: `
- Do these **companies** focus on this **geographic market** for this **hunt**?
    `,
  },
  HuntHuntSpaceRequirementContentPropertyUse: {
    name: "Space requirement property use",
    markdown: `
- Is this the correct **property use** for this part of the **space requirement**?
    `,
  },
  Loanname: {
    name: "Loan name",
    markdown: `
- Does it make sense for the **loan** to use this **name**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceAvailabilityGroupname: {
    name: "Available space grouping name",
    markdown: `
- Do people in the market know the **space grouping** by this **name**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  InvestmentGroupname: {
    name: "Portfolio investment name",
    markdown: `
- Do people in the market know the **portfolio** by this **name**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  InvestmentGroupLoan: {
    name: "Portfolio loan",
    markdown: `
- Does it make sense for the assets in this **loan portfolio** to be modeled as a single **loan**?
    `,
  },
  InvestmentPropertyRight: {
    name: "Investment property right",
    markdown: `
- Is this the correct **property right** being transacted?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the property rights in context.
    `,
  },
  LoanPropertyRight: {
    name: "Loan property right",
    markdown: `
- Is this **property right** securing the **loan**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the property rights in context.
    `,
  },
  InvestmentLoan: {
    name: "Investment loan",
    markdown: `
- Is this the correct **loan** being transacted?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the property rights in context.
    `,
  },
  Huntstate: {
    name: "Hunt status",
    markdown: `
- Is this the **current status** of the **hunt**?
    `,
  },
  Huntactive_date: {
    name: "Active date",
    markdown: `
- Is this when the **hunt began**? The **month** and **year** are more important than the day.
    `,
  },
  Huntfulfilled_date: {
    name: "Fulfilled date",
    markdown: `
- Is this when the **hunt successfully concluded**? The **month** and **year** are more important than the day.
    `,
  },
  Huntceased_date: {
    name: "Ceased date",
    markdown: `
- Is this when the **hunt ended without resulting in a deal**? The **month** and **year** are more important than the day.
    `,
  },
  Investmentstate: {
    name: "Investment status",
    markdown: `
- Is this the **current deal status**?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Investmentclosed_date: {
    name: "Closed date",
    markdown: `
- Is this when the deal **closed**? The **month** and **year** are more important than the day.
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Investmentlive_date: {
    name: "Live date",
    markdown: `
- Is this when the deal was **launched to the market**? The **month** and **year** are more important than the day.
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Investmentunder_contract_date: {
    name: "Under contract date",
    markdown: `
- Is this when the deal **went under contract**? The **month** and **year** are more important than the day.
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
    `,
  },
  CompanyInvolvementawarded_at: {
    name: "Awarded date",
    markdown: `
- Is this when the **company was chosen for the deal**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Investmentwithdrawn_date: {
    name: "Withdrawn date",
    markdown: `
- Are you sure the deal is **off the market**?
- Is this when the deal **was pulled off the market**? The **month** and **year** are more important than the day.
    `,
  },
  SpaceUsagemove_in_date: {
    name: "Lease/license move-in date",
    markdown: `
- Is this when the **tenant began occupying the space**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceUsagemove_out_date: {
    name: "Lease/license move-out date",
    markdown: `
- Is this when the **tenant stopped occupying the space**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceUsagecontract_state: {
    name: "Lease/license contract status",
    markdown: `
- Is this the **current contract status** for the **lease/license**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceUsageoccupancy_state: {
    name: "Lease/license occupancy status",
    markdown: `
- Is this the **current occupancy status** for the **lease/license**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceUsageexecuted_date: {
    name: "Execution date",
    markdown: `
- Is this when the lease/license was **executed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceUsagecommenced_date: {
    name: "Commencement date",
    markdown: `
- Is this when the lease/license **commenced**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceUsageterminated_date: {
    name: "Termination date",
    markdown: `
- Is this when the lease/license was **terminated**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceUsageexpired_date: {
    name: "Expiration date",
    markdown: `
- Is this when the lease/license **expires**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceAvailabilitystate: {
    name: "Space availability status",
    markdown: `
- Is this the **current status** for the **available space**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceAvailabilityclosed_date: {
    name: "Closed date",
    markdown: `
- Is this when the deal **closed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceAvailabilitylive_date: {
    name: "Live date",
    markdown: `
- Is this when the space was **initially marketed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  SpaceAvailabilityunder_contract_date: {
    name: "Under contract date",
    markdown: `
- Is this when **a Letter of Intent was signed** for the **space**? The **month** and **year** are more important than the day.
    `,
  },
  SpaceAvailabilitywithdrawn_date: {
    name: "Withdrawn date",
    markdown: `
- Are you sure the space is **off the market**?
- Is this when the space **was pulled off the market**? The **month** and **year** are more important than the day.
    `,
  },
  CompanyInvolvementInvolvementRole: {
    name: "Involvement role",
    markdown: `
- Do these **roles** match what the company did?
- If shown, use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see what the property diagram includes.
    `,
  },
  CompanyInvolvementContactCompanyInvolvement: {
    name: "Contact involvement",
    markdown: `
- Were these **people** part of the team working on this project?
    `,
  },
  FloorAreaname: {
    name: "Floor area name",
    markdown: `
- Is this a helpful **name** for this **block of space**?`,
  },
  LayoutPartitionname: {
    name: "Room name",
    markdown: `
- Is this a helpful **name** for this **room**?`,
  },
  LayoutPartitionstandardized_area: {
    name: "Standardized area",
    markdown: `
- Is this **square footage** accurate?
- Is this **square footage** linked to the correct **room**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor area, layouts, and rooms in context.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreastandardized_area: {
    name: "Standardized area",
    markdown: `
- Is this **square footage** accurate?
- Is this **square footage** linked to the correct **block of space**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor area in context.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaminimum_subdivision_standardized_area: {
    name: "Minimum subdivision (SF)",
    markdown: `
- Is this the **smallest possible subdivision** for this **block of space**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor area in context.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreause_based_area: {
    name: "Use-based area",
    markdown: `
- Is the **unit count** accurate?
- Is this **unit count** linked to the correct **block of space**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor area in context.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaContentPropertyUse: {
    name: "Property use",
    markdown: `
- Is this **use** accurate?
- Is this **use** linked to the correct **block of space**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor area in context.
    `,
  },
  HuntContentPropertyUse: {
    name: "Hunt property use",
    markdown: `
- Is the company pursuing investments with this **property use**?
    `,
  },
  PropertyPropertyContentLocation: {
    name: "Property location",
    markdown: `
- Is the {% inlineAppIcon iconName="marker" /%} **map marker** close to the contained **land parcel(s)** and **land covering(s)**?
- If there are any {% inlineAppIcon iconName="marker" /%} **nearby markers**, are you sure they don't already represent this property?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see what the property diagram includes.
- Are the **city** and **state** correct?
    `,
  },
  PropertyPropertyPropertyRight: {
    name: "Property rights",
    markdown: `
- Are all the **property rights** listed? Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the property rights in context.
    `,
  },
  LandCoveringhypothetical_date: {
    name: "Hypothetical date",
    markdown: `
- Is this when the land covering was **conceived of**? Since this is an early and informal milestone, the **year** is most indicative.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringconstruction_proposed_date: {
    name: "Construction proposed date",
    markdown: `
- Is this when the land covering was **proposed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringdemolition_proposed_date: {
    name: "Demolition proposed date",
    markdown: `
- Is this when **permission was requested to demolish** the land covering? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringconstruction_approved_date: {
    name: "Construction approved date",
    markdown: `
- Is this when the land covering was **approved**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringconstruction_permitted_date: {
    name: "Construction permitted date",
    markdown: `
- Is this when the land covering received a **building permit**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringconstruction_commenced_date: {
    name: "Construction commenced date",
    markdown: `
- Is this when **construction commenced** on the **land covering**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringconstruction_completed_date: {
    name: "Construction completed date",
    markdown: `
- Is this when the land covering was **built**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringdemolition_permitted_date: {
    name: "Demolition permitted date",
    markdown: `
- Is this when **permission was received to demolish** the land covering? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringdemolished_date: {
    name: "Demolished date",
    markdown: `
- Is this when the land covering was **demolished**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringwithdrawn_date: {
    name: "Withdrawn date",
    markdown: `
- Is this when the land covering proposal was **withdrawn from the entitlement process**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringstate: {
    name: "Construction status",
    markdown: `
- Is this the **current construction status** of the land covering?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreahypothetical_date: {
    name: "Hypothetical date",
    markdown: `
- Is this when the space was **conceived of**? Since this is an early and informal milestone, the **year** is most indicative.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaconstruction_proposed_date: {
    name: "Construction proposed date",
    markdown: `
- Is this when the space was **proposed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreademolition_proposed_date: {
    name: "Demolition proposed date",
    markdown: `
- Is this when **permission was requested to demolish** the space? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaconstruction_approved_date: {
    name: "Construction approved date",
    markdown: `
- Is this when the space was **approved**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaconstruction_permitted_date: {
    name: "Construction permitted date",
    markdown: `
- Is this when the space received a **building permit**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaconstruction_commenced_date: {
    name: "Construction commenced date",
    markdown: `
- Is this when **construction commenced** on the **space**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaconstruction_completed_date: {
    name: "Construction completed date",
    markdown: `
- Is this when the space was **built**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreademolition_permitted_date: {
    name: "Demolition permitted date",
    markdown: `
- Is this when **permission was received to demolish** the space? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreademolished_date: {
    name: "Demolished date",
    markdown: `
- Is this when the space was **demolished**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreawithdrawn_date: {
    name: "Withdrawn date",
    markdown: `
- Is this when the space proposal was **withdrawn from the approval process**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreastate: {
    name: "Construction status",
    markdown: `
- Is this the **current construction status** of the space?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayouthypothetical_date: {
    name: "Hypothetical date",
    markdown: `
- Is this when the layout was **conceived of**? Since this is an early and informal milestone, the **year** is most indicative.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutconstruction_proposed_date: {
    name: "Construction proposed date",
    markdown: `
- Is this when the layout was **proposed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutdemolition_proposed_date: {
    name: "Demolition proposed date",
    markdown: `
- Is this when **permission was requested to demolish** the layout? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutconstruction_approved_date: {
    name: "Construction approved date",
    markdown: `
- Is this when the layout was **approved**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutconstruction_permitted_date: {
    name: "Construction permitted date",
    markdown: `
- Is this when the layout received a **building permit**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutconstruction_commenced_date: {
    name: "Construction commenced date",
    markdown: `
- Is this when **construction commenced** on the **layout**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutconstruction_completed_date: {
    name: "Construction completed date",
    markdown: `
- Is this when the layout was **built**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutdemolition_permitted_date: {
    name: "Demolition permitted date",
    markdown: `
- Is this when **permission was received to demolish** the layout? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutdemolished_date: {
    name: "Demolished date",
    markdown: `
- Is this when the layout was **demolished**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutwithdrawn_date: {
    name: "Withdrawn date",
    markdown: `
- Is this when the layout proposal was **withdrawn from the approval process**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaLayoutstate: {
    name: "Construction status",
    markdown: `
- Is this the **current construction status** of the layout?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyEnhancementcreated_date: {
    name: "Created date",
    markdown: `
- Is this when the enhancement was **built/installed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyEnhancementdestroyed_date: {
    name: "Destroyed date",
    markdown: `
- Is this when the enhancement was **demolished/removed**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyEnhancementstate: {
    name: "Enhancement status",
    markdown: `
- Is this the **current construction status** of the enhancement?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightcommenced_date: {
    name: "Commenced date",
    markdown: `
- Is this when the property right **commenced**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightterminated_date: {
    name: "Terminated date",
    markdown: `
- Is this when the property right **terminated**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightexpired_date: {
    name: "Expired date",
    markdown: `
- Is this when the property right **expired**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightcreated_date: {
    name: "Created date",
    markdown: `
- Is this when the land parcel was **created**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightdestroyed_date: {
    name: "Destroyed date",
    markdown: `
- Is this when the land parcel was **dissolved**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightgranted_date: {
    name: "Granted date",
    markdown: `
- Is this when the air rights were **granted**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightused_date: {
    name: "Used date",
    markdown: `
- Is this when the air rights were **used**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyRightstate: {
    name: "Property right status",
    markdown: `
- Is this the **current status** of the property right?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  CompanyInvolvementengaged_date: {
    name: "Engaged date",
    markdown: `
- Is this when the company was **hired**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  CompanyInvolvementterminated_date: {
    name: "Terminated date",
    markdown: `
- Is this when the company **stopped working on the project**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  CompanyInvolvementstate: {
    name: "Company involvement status",
    markdown: `
- Is this the **current status** of the company on the project?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  FloorAreaSpaceAvailabilityFloorArea: {
    name: "Available space",
    markdown: `
- Do all of the listed **floor areas** belong on this **space availability**?
- If any of the **floor areas** are designated **multi-floor**, do the measurements make sense?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.
    `,
  },
  PropertyEnhancementSpaceAvailabilityPropertyEnhancement: {
    name: "Available space",
    markdown: `
- Do all of the listed **property enhancements** belong on this **space availability**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.
    `,
  },
  LayoutPartitionSpaceAvailabilityLayoutPartition: {
    name: "Available space",
    markdown: `
- Do all of the listed **rooms** belong on this **space availability**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.
    `,
  },
  FloorAreaSpaceAvailabilitySpaceUsage: {
    name: "Available sublease space",
    markdown: `
- Is this **space availability** a **sublease**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.
    `,
  },
  LandCoveringLandCoveringLevelFloorArea: {
    name: "Building level floor area",
    markdown: `
- Do all of the listed **floor areas** belong on this **building level**?
- If any of the **floor areas** are designated **multi-floor**, do the measurements make sense?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.
    `,
  },
  PropertyLandCoveringFloorArea: {
    name: "Land covering floor area",
    markdown: `
- Do all of the listed **floor areas** belong on this **land covering**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.
    `,
  },
  PropertyPropertyRightFloorArea: {
    name: "Air rights floor area",
    markdown: `
- Do all of the listed **floor areas** belong on these **air rights**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.
    `,
  },
  PropertyLandCoveringLandCoveringLevel: {
    name: "Building level",
    markdown: `
- Is this the correct number of **vertical levels** in the building?
- If present, are the number of **below-grade levels** correct?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
    `,
  },
  PropertyPropertyRightLandCovering: {
    name: "Land covering",
    markdown: `
- Are all the **land coverings** listed?
- Do the land coverings belong on this **property right**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the land coverings in context.
    `,
  },
  PropertyLandCoveringPropertyEnhancement: {
    name: "Building enhancement",
    markdown: `
- Does the **enhancement** belong on the building?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the enhancements in context.
    `,
  },
  LandCoveringLandCoveringDiagramVerticalGroupingLandCoveringLevel: {
    name: "Same-width levels",
    markdown: `
- Should the included **building levels** appear to be the same width in the diagram?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
    `,
  },
  OwnershipInterestCompanyInvolvement: {
    name: "Ownership company",
    markdown: `
- Were/are the **companies** an owner of this **asset**?
- Is this the correct asset (i.e., fee, leasehold, condo, (senior/junior) loan)
    `,
  },
  InvestmentCompanyInvolvementInvestmentOffer: {
    name: "Company investment bid",
    markdown: `
- Did this **company** submit an **offer**?
    `,
  },
  InvestmentGroupCompanyInvolvementInvestmentOffer: {
    name: "Company portfolio investment bid",
    markdown: `
- Did this **company** submit a **portfolio offer**?
    `,
  },
  InvestmentInvestmentOfferValuation: {
    name: "Investment bid",
    markdown: `
- Are the **bid prices** correct?
  `,
  },
  InvestmentGroupInvestmentOfferValuation: {
    name: "Investment bid",
    markdown: `
- Are the **bid prices** correct?
  `,
  },
  InvestmentGroupCompanyInvolvementValuation: {
    name: "Portfolio investment bid",
    markdown: `
- Was this company's **portfolio offer** accepted?
  `,
  },
  Loanloan_coupon_rate: {
    name: "Loan coupon rate",
    markdown: `
- Is the **interest rate** correct?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
  `,
  },
  Loanloan_term_years: {
    name: "Loan term",
    markdown: `
- Is the **length** of the **loan** correct?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
  `,
  },
  Loanmaturity_date: {
    name: "Loan maturity date",
    markdown: `
- Is the **maturity date** of the **loan** correct?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
  `,
  },
  Loanorigination_date: {
    name: "Loan origination date",
    markdown: `
- If a **date is present**, is this when the loan was **originated**? The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
  `,
  },
  Loanfully_funded_loan_amount: {
    name: "Loan amount",
    markdown: `
- Is the **fully-funded loan amount** correct? This is the initial amount of the loan plus any future funding that the lender has agreed to.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  Loanretired_date: {
    name: "Loan retired date",
    markdown: `
- Is this when the loan was **retired**? This often coincides with the maturity date but may be earlier. The **month** and **year** are more important than the day.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
  `,
  },
  Loanstate: {
    name: "Loan status",
    markdown: `
- Is this the **current loan status**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  Valuationvalue: {
    name: "Valuation value",
    markdown: `
- Is the **valuation** correct?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  FloorAreaLandCoveringLevelFloorAreaDownwardVerticalOpening: {
    name: "Floor area vertical opening",
    markdown: `
- Are all of the listed **floor areas** open to the **building level(s)** below?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
    `,
  },
  SpaceUsageCashflowSchedule: {
    name: "Lease/license cashflow schedule",
    markdown: `
- Do the listed **periodic cashflows** belong to this **lease/license**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
  `,
  },
  SpaceUsageCompanyInvolvement: {
    name: "Lease/license company involvement",
    markdown: `
- Were/are the **companies** involved with the **deal**?
  `,
  },
  SpaceAvailabilityCompanyInvolvement: {
    name: "Space availability company involvement",
    markdown: `
- Were/are the **companies** involved with the **space listing**?
  `,
  },
  SpaceAvailabilityCompanyInvolvementCompanyInvolvement: {
    name: "Available space prospect advisor",
    markdown: `
- Were/are the **companies** advising prospects for the **space listing**?
  `,
  },
  SpaceAvailabilityGroupSpaceAvailability: {
    name: "Available space grouping",
    markdown: `
- Were/are the **spaces** listed as a **group**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  InvestmentCompanyInvolvement: {
    name: "Investment company involvement",
    markdown: `
- Were/are the **companies** involved with the **deal**?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
  `,
  },
  InvestmentGroupInvestment: {
    name: "Portfolio investment",
    markdown: `
- Were/are the **deals** part of the **portfolio**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  PropertyEnhancementname: {
    name: "Enhancement name",
    markdown: `
- Does the **name** clearly describe the **enhancement**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  PropertyFloorAreaFloorAreaLayout: {
    name: "Floor area layout",
    markdown: `
- Does this **layout** belong with this **floor area**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  PropertyFloorAreaLayoutLayoutPartition: {
    name: "Layout room",
    markdown: `
- Do these **rooms** belong with this layout?
`,
  },
  PropertyPropertyRightPropertyEnhancement: {
    name: "Property right enhancement",
    markdown: `
- Does the **enhancement** belong on the property right?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the enhancements in context.
  `,
  },
  PropertyFloorAreaPropertyEnhancement: {
    name: "Floor area enhancement",
    markdown: `
- Does the **enhancement** belong on the floor area?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the enhancements in context.
  `,
  },
  SpaceUsageSpaceUsageCrowdsourcedFile: {
    name: "Lease/license file",
    markdown: `
- Does the **file** belong with the lease/license?`,
  },
  SpaceAvailabilitySpaceAvailabilityCrowdsourcedFile: {
    name: "Space availability file",
    markdown: `
- Does the **file** belong with the available space?`,
  },
  SpaceAvailabilityGroupCrowdsourcedFile: {
    name: "Space availability group file",
    markdown: `
- Does the **file** belong with the available space grouping?`,
  },
  InvestmentGroupInvestmentGroupCrowdsourcedFile: {
    name: "Portfolio investment file",
    markdown: `
- Does the **file** belong with the portfolio investment?`,
  },
  DataFieldCitationCrowdsourcedFile: {
    name: "Citation file",
    markdown: `
- Does the **file** belong with the citation?`,
  },
  PropertyPropertyCrowdsourcedFile: {
    name: "Property file",
    markdown: `
- Does the **file** belong with the property?`,
  },
  PropertyLandCoveringCrowdsourcedFile: {
    name: "Land covering file",
    markdown: `
- Does the **file** belong with the land covering?`,
  },
  PropertyPropertyRightCrowdsourcedFile: {
    name: "Property right file",
    markdown: `
- Does the **file** belong with the property right?`,
  },
  PropertyFloorAreaCrowdsourcedFile: {
    name: "Floor area file",
    markdown: `
- Does the **file** belong with the floor area?`,
  },
  PropertyFloorAreaLayoutCrowdsourcedFile: {
    name: "Floor area layout file",
    markdown: `
- Does the **file** belong with the floor area layout?`,
  },
  PropertyInvestmentCrowdsourcedFile: {
    name: "Investment file",
    markdown: `
- Does the **file** belong with the investment?`,
  },
  CompanyInvolvementCompanyInvolvement: {
    name: "Partner company",
    markdown: `
- Did these **companies** work together on the project?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?`,
  },
  CompanyInvolvementHunt: {
    name: "Company Hunt",
    markdown: `
- Does this **hunt** belong to this **company**?
- A company can have many hunts, each of which can describe a **target investment market/type** and/or a **space requirement**.`,
  },
  Hunthunts_equity: {
    name: "Hunt equity investment focus",
    markdown: `
- Does this accurately reflect whether or not this particular **hunt** involves **equity** investments?
- A company can have many hunts, each of which can describe a **target investment market/type** and/or a **space requirement**.
- Keep in mind that acquiring an ownership position in a loan/note is considered an equity investment even though the "asset" is debt.
    `,
  },
  Hunthunts_debt: {
    name: "Hunt financing focus",
    markdown: `
- Does this accurately reflect whether or not this particular **hunt** involves **lending/financing** investments?
- A company can have many hunts, each of which can describe a **target investment market/type** and/or a **space requirement**.
    `,
  },
  Huntmin_investment_size: {
    name: "Hunt minimum target investment size",
    markdown: `
- Is this the low-end **dollar value** that the company will pursue?
- This datapoint refers to **deal size**, not **equity invested** (which usually translates to a larger deal size).
    `,
  },
  Huntmax_investment_size: {
    name: "Hunt maximum target investment size",
    markdown: `
- Is this the high-end **dollar value** that the company will pursue?
- This datapoint refers to **deal size**, not **equity invested** (which usually translates to a larger deal size).
    `,
  },
  HuntHuntSpaceRequirementmin_area_per_person: {
    name: "Space requirement minimum density",
    markdown: `
- Does this reflect the company's desired low-end **per-person density** for this space requirement?
- This is an especially subjective datapoint that is correct as long as it matches the company's preferences.
    `,
  },
  HuntHuntSpaceRequirementmax_area_per_person: {
    name: "Space requirement maximum density",
    markdown: `
- Does this reflect the company's desired high-end **per-person density** for this space requirement?
- This is an especially subjective datapoint that is correct as long as it matches the company's preferences.
    `,
  },
  HuntHuntSpaceRequirementdensity_percent_modifier: {
    name: "Space requirement density modifier",
    markdown: `
- Does this reflect the company's desired **density margin of error** (ie, how far off in either direction) for this space requirement?
- This is an especially subjective datapoint that is correct as long as it matches the company's preferences.
    `,
  },
  HuntSpaceRequirementPhasemin_headcount: {
    name: "Space requirement phase minimum headcount",
    markdown: `
- Does this reflect the company's estimated low-end **headcount** for this **phase** of the **space requirement**?
    `,
  },
  HuntSpaceRequirementPhasemax_headcount: {
    name: "Space requirement phase maximum headcount",
    markdown: `
- Does this reflect the company's estimated high-end **headcount** for this **phase** of the **space requirement**?
    `,
  },
  HuntSpaceRequirementPhasemin_area: {
    name: "Space requirement phase minimum square footage",
    markdown: `
- Does this reflect the company's estimated low-end **square footage** for this **phase** of the **space requirement**?
    `,
  },
  HuntSpaceRequirementPhasemax_area: {
    name: "Space requirement phase maximum square footage",
    markdown: `
- Does this reflect the company's estimated high-end **square footage** for this **phase** of the **space requirement**?
    `,
  },
  HuntSpaceRequirementPhasetarget_date: {
    name: "Space requirement phase target date",
    markdown: `
- Is this **when** the company expects to need the additional space described in this **phase**? The **month** and **year** are more important than the day.
    `,
  },
  Hunttarget_occupancy_date: {
    name: "Space requirement target occupancy date",
    markdown: `
- Is this **when** the company wants to **be in its new space**? The **month** and **year** are more important than the day.
    `,
  },
  HuntHuntGeographyIntentGeographyRegion: {
    name: "Target market boundaries",
    markdown: `
- Does the **map polygon** effectively identify the target geography for this **hunt**?
`,
  },
  HuntHuntGeographyIntent: {
    name: "Hunt target market",
    markdown: `
- Are these the correct **target markets** for this **hunt**?
`,
  },
  HuntHuntSpaceRequirement: {
    name: "Hunt space requirement",
    markdown: `
- Are these the correct **space requirements** for this **hunt**?
`,
  },
  HuntHuntSpaceRequirementSpaceRequirementPhase: {
    name: "Space requirement phase",
    markdown: `
- Does this **space requirement** involve the **phases** shown?
`,
  },
  HuntCompanyInvolvement: {
    name: "Hunt company",
    markdown: `
- Were/are the **companies** supporting this **hunt**?
    `,
  },
  FloorAreaLayoutname: {
    name: "Floor area layout name",
    markdown: `
- Is this a helpful **name** for this **floor area layout**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the layout in context.
    `,
  },
  LandCoveringLevelname: {
    name: "building level name",
    markdown: `
- Is this a helpful **name** for this **building level**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
    `,
  },
  LandCoveringDiagramVerticalGroupingname: {
    name: "Same-width floor grouping name",
    markdown: `
- Is this a helpful **name** for this group of **same-width building levels**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
    `,
  },
  LandCoveringheight: {
    name: "Building height",
    markdown: `
- Is this the correct **building height** (within a few feet)?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  LandCoveringLevelheight: {
    name: "Building level slab-to-slab height",
    markdown: `
- Is this the correct **slab-to-slab height** (within a foot)?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  LandCoveringLandCoveringLevelhorizontal_alignment: {
    name: "Building level horizontal alignment",
    markdown: `
- Are these the correct **alignments** for these **building levels**? It should help the diagram building look more like the real building.
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the building levels in context.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  LandCoveringLandCoveringLevelPropertyDiagramVisualOrdering: {
    name: "Floor area horizontal order",
    markdown: `
- Are the floor areas **horizontally ordered** in a way that makes sense in relation to real life?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.`,
  },
  PropertyRightPropertyRightPropertyDiagramVisualOrdering: {
    name: "Property right horizontal order",
    markdown: `
- Are the property rights **horizontally ordered** in a way that makes sense in relation to real life?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the property rights in context.`,
  },
  PropertyPropertyRightPropertyDiagramVisualOrdering: {
    name: "Property right horizontal order",
    markdown: `
- Are the property rights **horizontally ordered** in a way that makes sense in relation to real life?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the property rights in context.`,
  },
  PropertyRightLandCoveringPropertyDiagramVisualOrdering: {
    name: "Land covering horizontal order",
    markdown: `
- Are the land coverings **horizontally ordered** in a way that makes sense in relation to real life?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the land coverings in context.`,
  },
  PropertyLandCoveringPropertyDiagramVisualOrdering: {
    name: "Floor area vertical order",
    markdown: `
- Are the floor areas **vertically ordered** in a way that makes sense in relation to real life?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the floor areas in context.`,
  },
  PropertyLandCoveringGroupingLandCovering: {
    name: "Land covering grouping",
    markdown: `
- Do the included **land coverings** represent a **unified physical structure** in real life?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the land coverings in context.`,
  },
  LandCoveringGroupingname: {
    name: "Land covering grouping name",
    markdown: `
- Does this **name** properly describe the **land covering grouping**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  LandCoveringname: {
    name: "Land covering name",
    markdown: `
- Is this the **land covering's** proper **name**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the land covering in context.`,
  },
  LandCoveringstreet_address: {
    name: "Land covering street address",
    markdown: `
- Is this the **land covering's** correct **address**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the land covering in context.`,
  },
  LandCoveringPropertyRightFloorArea: {
    name: "Air rights floor area",
    markdown: `
- Do all of the listed **floor areas** belong with this **condo/co-op**?`,
  },
  InvestmentOwnershipInterest: {
    name: "Investment ownership interests",
    markdown: `
- Were these **ownership interests** affected by the **deal**?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
    `,
  },
  PropertyRightOwnershipInterest: {
    name: "Property right ownership interests",
    markdown: `
- Do these **ownership interests** belong with this **property right**?
    `,
  },
  OwnershipInterestretired_at: {
    name: "Ownership interest retired date",
    markdown: `
- Is this when the **ownership interest** was **retired**? This often coincides with an **investment closing date** or **loan pay-off date**. The **month** and **year** are more important than the day.
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?
    `,
  },
  PropertyLandCoveringContentLocation: {
    name: "Land covering location",
    markdown: `
- Does the {% inlineAppIcon iconName="marker" /%} **map marker** correctly identify the **land covering**?
- If there are any {% inlineAppIcon iconName="marker" /%} **nearby markers**, are you sure they don't already represent this land covering?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the land covering in context.
- Are the **city** and **state** correct?`,
  },
  Propertystreet_address: {
    name: "Property street address",
    markdown: `
- Does the **address** effectively describe the property's **land parcel(s)** and **land covering(s)**?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see what the property diagram includes.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  PropertyPropertyRightContentPolygon: {
    name: "Fee parcel boundaries",
    markdown: `
- Does the **map polygon** effectively identify the parcel boundaries? Exact measurements are not necessary, but it should pass a visual inspection by a market expert.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  PropertyPropertyRightPropertyRight: {
    name: "Subordinate property right",
    markdown: `
- Are all the **subordinate property rights** included?
- Use the {% inlineAppIcon iconName="rectangleStack" /%} **View diagram** button above to see the property rights in context.`,
  },
  PropertyRightland_area: {
    name: "Land area",
    markdown: `
- Is the **acreage** correct? Exact measurements are not necessary, but it should pass a visual inspection by a market expert.
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  PropertyRightname: {
    name: "Property right name",
    markdown: `
- Is this a **helpful name** for this **property right**?
- **Bonus**: Is there at least one {% inlineAppIcon iconName="book" /%} **citation** to back it up?`,
  },
  Valuationvalue_type: {
    name: "Valuation type",
    markdown: `
- Is this the correct **valuation type**?
- Are there any **potential duplicate investments** (shown above)? If so, are you sure this investment is not a duplicate?`,
  },
  DataFieldChangeGroupCitationChangeGroup: {
    name: "Datapoint citation",
    markdown: `
- Is this **citation** supporting the correct **datapoint**?`,
  },
  CitationChangeGroupdescription: {
    name: "Datapoint citation description",
    markdown: `
- Does this **description** point someone to the relevant info within the **citation website/file**?`,
  },
  DataFieldCitationname: {
    name: "Citation name",
    markdown: `
- Is this a helpful **name** for the **citation**?`,
  },
  DataFieldCitationurl: {
    name: "Citation website link",
    markdown: `
- Does the **link** appear to point to a helpful and legitimate website?`,
  },
  DataFieldCitationpaywalled: {
    name: "Citation paywall",
    markdown: `
- Does the **linked website** sit behind a **paywall** that will prevent people from accessing it?`,
  },
  DataFieldCitationdate: {
    name: "Citation name",
    markdown: `
- Does this **date** provide useful context for the **citation**?`,
  },
};
